<template>
  <div id="PolicySelect">
    <div style="height: 28px"></div>
    <van-form @submit="onSubmit">
      <van-field
          clearable
          v-model="insuredName"
          name="insuredName"
          label="姓名"
          placeholder="被保人姓名"
          :rules="[{ required: true, message: '请填写被保人姓名' }]"
      />
      <van-field
          clearable
          v-model="insuredCardNo"
          name="insuredCardNo"
          label="身份证"
          placeholder="被保人身份证号码"
          :rules="[{ required: true, message: '请填写被保人身份证号码' }]"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">
          查询
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
// import { getClaimPolicyList } from '@/api/api'


export default {
  name: 'PolicySelect',
  data() {
    return {
      insuredCardNo: '',
      insuredName: '',
    };
  },
  methods: {
    onSubmit(values) {
      this.$router.push({path: '/claim/policyList', query: {insuredCardNo: values.insuredCardNo, insuredName: values.insuredName}});
    },
  },
}
</script>
